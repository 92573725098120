import React, { useReducer, createContext } from "react";
import { TravauxReducer } from "../reducers/TravauxReducer";
// Data
import { travauxCardsData } from "../data/travauxCardsData";

export const TravauxContext = createContext();

const TravauxContextProvider = ({ children }) => {
  const [travauxData, dispatch] = useReducer(TravauxReducer, {
    cards: travauxCardsData,
    selectedCard: "",
    showCard: false,
    filters: {
      réclamations: {
        id: "réclamations",
        value: true,
      },
      travaux: {
        id: "travaux",
        value: true,
      },
    },
  });

  return (
    <TravauxContext.Provider value={{ travauxData, dispatch }}>
      {children}
    </TravauxContext.Provider>
  );
};

export default TravauxContextProvider;
