import React from "react";

export const travauxCardsData = [
  {
    id: "nc1",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des salles de bains et WC",
    description:
      "Les pièces humides de vos logements font peau neuve ! Pour cela, vos équipements sanitaires seront remplacés et les revêtements entièrement refaits: les murs seront repeints et une nouvelle faïence sera posée; les anciens revêtements de sol seront quant à eux enlevés puis remplacés.",
    descriptionPlus:
      "Vous pourrez choisir lors de la visite avant travaux entre deux références de sol et votre préférence pour une douche ou une baignoire.",
    thumbnail: "nc1thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/HXeO54nVPiU",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc2",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des cuisines",
    description:
      "Votre cuisine mérite un petit coup de fraîcheur et sera pour cela refaite du sol au plafond ! Le sol, la peinture ou encore la faïence sur les murs seront refaits. Un nouveau plan de travail ainsi que des rangements (de type caisson IKEA) seront installés et un nouvel évier posé. Pour l’évier, vous aurez librement le choix entre un évier 1.5 bac et un évier 2 bacs, sous réserve de la place disponible en fonction de l’aménagement de la cuisine.",
    descriptionPlus:
      "Vous pourrez choisir lors de la visite avant travaux entre deux références de plan de travail et deux références de sol, ainsi que la taille de l'évier",
    thumbnail: "nc2thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/yd4rDiK1vP4",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc3",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "de l’électricité des logements",
    description:
      "Pour des raisons de sécurité et de commodités, l’électricité de votre logement doit être entièrement revue. D’abord le tableau électrique, les prises électriques, les interrupteurs et les points d’éclairage et luminaires seront remplacés. Ensuite des goulottes électriques seront posées pour éviter que les fils se retrouvent à l’air libre dans votre logement. Enfin, si votre machine à laver se retrouve trop proche de la douche ou de la baignoire, elle sera déplacée pour éviter tout risque d’électrocution.",
    descriptionPlus:
      "Vous pourrez choisir lors de la visite avant travaux l’emplacement des prises.",
    thumbnail: "nc3thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/iZSRScUxMz4",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc4",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des fenêtres selon leur état",
    description:
      "Selon l’état de vos fenêtres, les joints seront remplacés pour que vous gagniez en isolation mais également toute la quincaillerie: poignée, gonds, crémones...en plus de cela les petits bois des fenêtres décollés seront remis en place.",
    descriptionPlus: "",
    thumbnail: "nc4thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/KdtadqBir3E",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc5",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Les autres interventions",
    titleWeak: "",
    description:
      "Ces interventions sont d’ordre plus général puisqu’elles concernent l’intégralité de votre logement. C’est le cas, notamment des robinets thermostatiques des radiateurs qui seront remplacés ainsi que votre porte d’entrée. Et de manière, plus ponctuelle (selon que votre logement est concerné ou non) la réparation du parquet dégradé devant la salle de bain.",
    descriptionPlus: "",
    thumbnail: "nc5thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/CeW25-ToCiA",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc6",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des escaliers",
    description:
      "La cage d’escalier de votre immeuble est victime de son grand âge et à besoin d’être rénovée. Cette rénovation est d’abord d’ordre esthétique: en reprenant les parquets abîmés et en refaisant la peinture. Mais également de l’ordre de la sécurité et de la mise en conformité: Les garde corps existants sont conservés mais adaptées pour être mis aux normes. Concrètement, cela se fera par la réduction de l’espace entre les barreaux existants grâce à la soudure de profilés métalliques. L’éclairage actuel sera remplacé par des éclairages à LED avec détecteur de présence et toutes les gaines techniques seront révisées !",
    descriptionPlus: "",
    thumbnail: "nc6thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/jeYSsxfidcI",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc7",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des halls",
    description:
      "Les halls de l’immeuble sont sans aucun doute les endroits qui ont vu passer le plus de monde au cours des ans. Par conséquent, ils se sont naturellement dégradés et méritent aujourd’hui une seconde jeunesse ! Généralement, les mosaïques seront rénovées, les peintures refaites ainsi que les éclairages qui passeront en LED avec détecteur de présence..enfin les portes de hall seront révisées. Pour une plus grande sécurité et praticité pour les résidents, un contrôle d’accès par badge sur les deux entrées des halls sera installé ainsi qu’un interphone. Enfin, les passages vers la rue Lamartine seront aménagés en hall secondaire dans lesquels les résidents pourront - grâce à un mobilier adapté - accrocher des poussettes et avec la pose d’étagères pourront y déposer des livres à échanger, des plantes…",
    descriptionPlus: "",
    thumbnail: "nc7thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/dwpA1FGI_E0",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc8",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "des installations de ventilation",
    description:
      "Afin de garantir le bon renouvellement de l’air dans votre logement et l’évacuation des odeurs de cuisine, le système de ventilation de votre immeuble sera rénové. Cela commence dans votre logement avec le remplacement des bouches d’entrée d'air et d’extraction d’air... jusqu’en toiture de l’immeuble où les moteurs et les réseaux de ventilations seront revus.",
    descriptionPlus: "",
    thumbnail: "nc8thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/G-SFqGGG3DU",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc9",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Le ravalement",
    titleWeak: "des façades",
    description:
      "Les années, les diverses précipitations ou encore la pollution ont abîmé naturellement les façades de votre immeuble. Il est temps de revoir tout ça ! Aussi, les éléments de maçonnerie dégradés seront réparés, les fissures bouchées, les bétons repeints et les briques nettoyées. Au-delà de ce grand nettoyage, les volets de vos logements seront retirés pour être rénovés en atelier et reposés ensuite. Enfin, pour assurer votre sécurité, les garde-corps situés devant les porte-fenêtres seront mise en conformité.",
    descriptionPlus: "",
    thumbnail: "nc9thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/kqNAMn9D4vs",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc10",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "L’étanchéité",
    titleWeak: "",
    description:
      "Pour éviter l’infiltration des eaux de pluies qui fragilisent et dégradent sur le long terme votre immeuble, un gros travail d’étanchéité sera fait sur les sols de tous les balcons. Pour les terrasses privatives et les terrasses techniques, un isolant (en plus de l’étanchéité) sera aussi posé pour améliorer les performances énergétiques des logements situés en dessous. Les dalles gravillonnées des terrasses privatives seront remplacées par des lames de terrasse d’aspect bois.",
    descriptionPlus: "",
    thumbnail: "nc10thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/wps8x_hu-oA",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc11",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "Le réaménagement",
    titleWeak: "des cours sur la rue Lamartine",
    description:
      "Les cours sont un peu le prolongement extérieur de votre foyer et constituent des aires de respiration dont tous les résidents ont besoin. Pour leur rendre justice, les cheminements y seront rénovés et de la nature plantée ! Dans la même idée, un abri poubelle végétalisé y sera aménagé. Enfin, pour garantir votre sécurité de l’éclairage y sera installé et des contrôles d'accès au niveau des portillons seront posés.",
    descriptionPlus: "",
    thumbnail: "nc11thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/Nt8O9UeFl3A",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
  {
    id: "nc12",
    type: "nonConcertableSpecial",
    layout: "nonConcertableLayoutSpecial",
    titleStrong: "La rénovation",
    titleWeak: "de l’allée Léopoldine",
    description:
      "Que serait Victor Hugo sans Léopoldine ? Et votre immeuble sans son l'allée Léopoldine ? L’évidence de la réponse appelle à une rénovation de grande ampleur ! De manière générale, les cheminements seront revus (revêtements des sols et escaliers), de la nature plantée, de l’éclairage installé et la clôture rénovée (ravalement du muret et peinture de la grille). Plus particulièrement, côté Sadi Carnot, un abri à vélo sera aménagé ainsi qu’un espace de convivialité.  Côté Victor Hugo, un autre local vélo sera construit, un local d’entretien aménagé ainsi qu’une salle de réunion pour l’amicale de locataires...et bien sûr...le banc patrimonial sera rénové.",
    descriptionPlus: "",
    thumbnail: "nc12thumb.png",
    tags: "travaux",
    video: {
      url: "https://youtu.be/PtuOmoCAX9Q",
      width: 320,
      height: 400,
    },
    notices: [],
    submitted: false,
    comment: "",
  },
];
