import React from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button } from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

const StepTwo = ({ setStep, setTagOne, tagOne }) => {
  const categories = [
    {
      id: "logement",
      value: "logement",
      label: "Votre logement",
      image: "appMain.svg",
    },
    {
      id: "immeuble",
      value: "immeuble",
      label: "Votre immeuble",
      image: "immMain.svg",
    },
    {
      id: "résidence",
      value: "résidence",
      label: "Votre résidence",
      image: "residMain.svg",
    },
    { id: "autre", value: "autre", label: "Autre", image: "autre_globale.svg" },
  ];

  return (
    <div className={classes.StepContent}>
      <div className={classes.Question}>
        <div className={classes.QuestionTitle}>
          <HelpOutlineRoundedIcon style={{ fontSize: 40, color: "#3B4578" }} />
          <p>
            Votre demande concerne ? (Choisissez parmi les options proposées
            pour passer à l’étape suivante)
          </p>
        </div>
        <form className={classes.RadioFormTwo}>
          {categories.map((option) => (
            <div className={classes.RadioElementTwo} key={option.id}>
              <input
                type="radio"
                name={option.id}
                value={option.value}
                onChange={(e) => {
                  setTagOne(e.target.value);
                  setStep(2);
                }}
                id={option.id}
              />
              <label
                htmlFor={option.value}
                style={{
                  backgroundImage: `url(${require(`../../../../assets/travaux/reclamation/${option.image}`)})`,
                }}
              ></label>
              <p>{option.label}</p>
            </div>
          ))}
        </form>
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setStep(0)}
        >
          Retour
        </Button>
      </div>
    </div>
  );
};

export default StepTwo;
