import React from "react";
import classes from "./CalendarModal.module.css";

const CalendarModal = ({ step }) => {
  if (step) {
    return (
      <div className={classes.CalendarModal}>
        <div className={classes.StepTitle}>
          <span className={classes.TitleEmoji} role="img" aria-label="emoji">
            {step.emoji}
          </span>
          <p>
            <strong>Étape {step.id + 1} : </strong>
            {step.title}
          </p>
        </div>
        <div className={classes.Substeps}>
          {step.substeps.map((substep) => (
            <div className={classes.Substep} key={substep.id}>
              <div className={classes.Description}>
                <span className={classes.Emoji} role="img" aria-label="emoji">
                  {substep.emoji}
                </span>
                <div>
                  <p>
                    <strong>{substep.title}</strong>
                  </p>
                  <p style={{ fontSize: ".78rem" }}>{substep.date}</p>
                  <p style={{ fontSize: ".78rem" }}>
                    Durée : {substep.duration}
                  </p>
                </div>
              </div>
              <p style={{ fontSize: ".9rem" }}>{substep.description}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
};

export default CalendarModal;
