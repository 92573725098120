export const CalendarData = [
  {
    id: 0,
    emoji: "👷",
    title: "Préparation des travaux",
    date: "Janvier à mars 2021",
    duration: "1 mois",
    substeps: [
      {
        id: 0,
        emoji: "👪",
        title: "Réalisation du logement témoin",
        date: "Janvier à mars 2021",
        duration: "1 mois",
        description:
          "Pour avoir un aperçu des travaux qui seront réalisés dans votre logement, un logement témoin sera aménagé dans votre immeuble.",
      },
      {
        id: 1,
        emoji: "👀",
        title: "Visite des locataires",
        date: "Dernière semaine de janvier 2021",
        duration: "1 semaine",
        description:
          "Un premier état des lieux de votre logement sera effectué par nos équipes durant cette semaine. ",
      },
      {
        id: 2,
        emoji: "👷",
        title: "Visite avant travaux (février - mars 2021)",
        date: "Février à mars 2021",
        duration: "1 mois",
        description:
          "Une fois l’enveloppe travaux précisée, un second état des lieux de votre logement sera effectué par nos équipes durant cette semaine avant de lancer les travaux.",
      },
    ],
  },
  {
    id: 1,
    emoji: "🏠",
    title: "Exécution des travaux dans les logements",
    date: "Mi mars - décembre 2021",
    duration: "8 mois",
    substeps: [
      {
        id: 0,
        emoji: "🏠",
        title: "",
        date: "Mi mars - décembre 2021",
        duration: "8 mois",
        description:
          "Cette étape concerne les travaux dans votre logement. Les travaux n’auront pas lieu en même temps dans tous les logements de l’immeuble mais seront découpés dans le temps par cage d’escalier afin de faciliter le travail des entreprises et assurer votre tranquillité. Par ailleurs, trois campagnes ponctuelles de travaux auront lieu: une pour les ventilations, une autre pour les menuiseries (Porte palière et fenêtres) et la dernière pour le chauffage.",
      },
      {
        id: 1,
        emoji: "🏠",
        title: "Les logements de la cage 7A",
        date: "Mi mars - mi avril 2021",
        duration: "5 semaines",
        description: "",
      },
      {
        id: 2,
        emoji: "🏠",
        title: "Les logements de la cage 7B",
        date: "Avril 2021",
        duration: "4 semaines",
        description: "",
      },
      {
        id: 3,
        emoji: "🏠",
        title: "Les logements de la cage 6",
        date: "Mai 2021",
        duration: "5 semaines",
        description: "",
      },
      {
        id: 4,
        emoji: "🏠",
        title: "Les logements de la cage 5",
        date: "Juin 2021",
        duration: "4 semaines",
        description: "",
      },
      {
        id: 5,
        emoji: "🏠",
        title: "Les logements de la cage 4",
        date: "Mi juin 2021",
        duration: "4 semaines",
        description: "",
      },
      {
        id: 6,
        emoji: "🏠",
        title: "Les logements de la cage 3",
        date: "Juillet - septembre 2021 (en dehors des vacances d'août)",
        duration: "5 semaines",
        description: "",
      },
      {
        id: 7,
        emoji: "🏠",
        title: "Les logements de la cage 2",
        date: "Septembre 2021",
        duration: "5 semaines",
        description: "",
      },
      {
        id: 8,
        emoji: "🏠",
        title: "Les logements de la cage 1",
        date: "Octobre 2021",
        duration: "5 semaines",
        description: "",
      },
    ],
  },
  {
    id: 2,
    emoji: "👪",
    title: "Exécution des travaux en parties communes",
    date: "Avril - décembre 2021",
    duration: "9 mois",
    substeps: [
      {
        id: 0,
        emoji: "👪",
        title: "",
        date: "Avril - décembre 2021",
        duration: "9 mois",
        description:
          "L'exécution de ces travaux suit la même logique de répartition par cage d’escalier que pour les travaux dans les logements.",
      },
      {
        id: 2,
        emoji: "👼",
        title: "Les gardes-corps",
        date: "Avril - mi juillet 2021",
        duration: "16 semaines (2 semaines par cage d’escalier)",
        description:
          "Ces  travaux consistent à sécuriser les gardes-corps dans les parties communes et seront répartis dans l’année par tranche de deux semaines pour chaque cage d’escalier: Cage 6 et 5 (avril 2021), cage 4 (avril-mai 2021), cage 3 (mai 2021), cage 2 (mai-juin 2021), cage 1 et 7A (juin 2021), cage 7B (juillet 2021)",
      },
      {
        id: 3,
        emoji: "🔌",
        title: "L'électricité",
        date: "Mi avril - novembre 2021",
        duration: "8 semaines (1 semaine par cage d’escalier)",
        description:
          "Ces  travaux consistent à changer les installations électriques dans les parties communes et seront répartis dans l’année par tranche d’une semaine pour chaque cage d’escalier: Cage 7A (avril 2021), cage 7B (mai 2021), cage 6 et 5 (juin 2021), cage 4 (juillet 2021), cage 3 (septembre 2021), cage 2 (octobre 2021), cage 1 (novembre 2021)",
      },
      {
        id: 4,
        emoji: "🧱",
        title: "La maçonnerie",
        date: "Mi avril - novembre 2021",
        duration: "8 semaines (1 semaine par cage d’escalier)",
        description:
          "Ces  travaux consistent à revoir toutes les maconneries dans les parties communes et seront répartis dans l’année par tranche d’une semaine pour chaque cage d’escalier: Cage 7A (avril 2021), cage 7B (mai 2021), cage 6 (juin 2021), cage 5 et 4 (juillet 2021), cage 3 (septembre 2021), cage 2 (octobre 2021), cage 1 (novembre 2021)",
      },
      {
        id: 5,
        emoji: "🔨",
        title: "La menuiserie",
        date: "Mai - décembre 2021",
        duration: "16 semaines (2 semaines par cage d’escalier)",
        description:
          "Ces  travaux consistent à revoir toutes les menuiseries dans les parties communes et seront répartis dans l’année par tranche de deux semaines pour chaque cage d’escalier: Cage 7A (mai 2021), cage 7B (mai - juin 2021), cage 6 (juin 2021), cage 5 (juillet 2021), cage 4 (septembre 2021), cage 3 (octobre 2021), cage 2 (novembre 2021), cage 1 (décembre 2021)",
      },
      {
        id: 6,
        emoji: "🎨",
        title: "La peinture",
        date: "Mai - décembre 2021",
        duration: "16 semaines (2 semaines par cage d’escalier)",
        description:
          "Ces  travaux consistent à repeindre les murs et plafonds de toutes les parties communes et seront répartis dans l’année par tranche de deux semaines pour chaque cage d’escalier: Cage 7A (mai 2021), cage 7B (juin 2021), cage 6 (juin-juillet 2021), cage 5 (juillet 2021), cage 4 (septembre 2021), cage 3 (octobre 2021), cage 2 (novembre 2021), cage 1 (décembre 2021)",
      },
      {
        id: 7,
        emoji: "🟫",
        title: "Le parquet",
        date: "Octobre - décembre 2021",
        duration: "8 semaines (1 semaine par cage d’escalier)",
        description:
          "Ces  travaux consistent à reprendre les parquets abîmés de toutes les parties communes et seront exécutés à la fin des travaux dans une courte période: Cage 7A (octobre 2021), cage 7B, 6, 5, 4 (novembre 2021), cage 3, 2, 1 (décembre 2021)",
      },
      {
        id: 8,
        emoji: "🔊",
        title: "L’interphonie",
        date: "",
        duration: "Non communiquée",
        description: "",
      },
    ],
  },
  {
    id: 3,
    emoji: "🏢",
    title: "Exécution des travaux extérieurs: le ravalement des façades",
    date: "Mars 2021 - mars 2022",
    duration: "1 an",
    substeps: [
      {
        id: 0,
        emoji: "🚧",
        title: "La pose des échafaudages",
        date: "Mars - novembre 2021",
        duration: "6 semaines (2 semaines par tranche)",
        description:
          "La pose des échafaudages se fera en trois tranches pendant l’année des travaux. La pose des échafaudages prend 2 semaines pour chaque tranche: tranche 1 (mars 2021), tranche 2 (juillet 2021), tranche 3 (novembre 2021)",
      },
      {
        id: 1,
        emoji: "🧱",
        title: "Le nettoyage et la réfection des maçonneries en briques",
        date: "Mars 2021 - janvier 2022",
        duration: "27 semaines (9 semaines par tranche)",
        description:
          "Le nettoyage et la réfection des maçonneries en briques des façades se fera en trois tranches pendant l’année des travaux. Les travaux prennent 9 semaines pour chaque tranche: tranche 1 (mars - mai 2021), tranche 2 (juillet - septembre 2021), tranche 3 (décembre 2021 - janvier 2022)",
      },
      {
        id: 2,
        emoji: "🎨",
        title: "La peinture des façades enduites",
        date: "Avril 2021 - février 2022",
        duration: "24 semaines (8 semaines par tranche)",
        description:
          "La peinture des façades enduites se fera en trois tranches pendant l’année des travaux. Les travaux prennent 8 semaines pour chaque tranche: tranche 1 (avril - juin 2021), tranche 2 (août - octobre 2021), tranche 3 (décembre 2021 - février 2022)",
      },
      {
        id: 3,
        emoji: "👼",
        title: "La reprise des gardes-corps, des fenêtres et des balcons",
        date: "Mai 2021 - février 2022",
        duration: "6 semaines (2 semaines par tranche)",
        description:
          "La reprise des balcons, garde-corps et fenêtres de la façade se fera en trois tranches pendant l’année des travaux. Les travaux prennent 2 semaines pour chaque tranche: tranche 1 (mai -juin 2021), tranche 2 (septembre - octobre 2021), tranche 3 (février 2022)",
      },
      {
        id: 4,
        emoji: "☔",
        title: "La dépose des volets",
        date: "Mars 2021 - décembre 2021",
        duration: "3 semaines (1 semaine par tranche)",
        description:
          "Cette opération consiste à enlever tous les volets de la façade pour les retaper en usine. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 1 semaine pour chaque tranche: tranche 1 (mars 2021), tranche 2 (juillet 2021), tranche 3 (décembre 2021)",
      },
      {
        id: 5,
        emoji: "☔",
        title: "La pose des volets",
        date: "Juin 2021 - février 2022",
        duration: "9 semaines (3 semaines par tranche)",
        description:
          "Cette opération consiste à remettre tous les volets retapés sur la façade. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 3 semaines pour chaque tranche: tranche 1 (juin 2021), tranche 2 (octobre 2021), tranche 3 (février 2022)",
      },
      {
        id: 6,
        emoji: "💧",
        title: "Le remplacement des gouttières",
        date: "Mai 2021 - février 2022",
        duration: "12 semaines (4 semaines par tranche)",
        description:
          "Cette opération consiste à remplacer le réseau d’évacuation des eaux pluviales présent sur les façades du bâtiment afin d’assurer un bon écoulement et de supprimer la stagnation de l’eau sur les balcons",
      },
      {
        id: 7,
        emoji: "🚧",
        title: "Le démontage des échafaudages",
        date: "Mai 2021 - mars 2022",
        duration: "6 semaines (2 semaines par tranche)",
        description:
          "Cette opération consiste au démontage des échafaudages suite aux travaux sur la façade. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 2 semaines pour chaque tranche: tranche 1 (juin - juillet 2021), tranche 2 (novembre 2021), tranche 3 (mars 2022)",
      },
    ],
  },
  {
    id: 4,
    emoji: "🏰",
    title: "Exécution des travaux extérieurs: les toitures",
    date: "Mars 2021 - février 2022",
    duration: "11 mois",
    substeps: [
      {
        id: 0,
        emoji: "🐓",
        title: "La dépose des acrotères des terrasses inaccessibles",
        date: "Mars 2021 - décembre 2021",
        duration: "6 semaines (2 semaines par tranche)",
        description:
          "Cette opération consiste à retirer les acrotères sur les toitures inaccessibles. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 2 semaines pour chaque tranche: tranche 1 (mars - avril 2021), tranche 2 (juillet - août 2021), tranche 3 (décembre 2021)",
      },
      {
        id: 1,
        emoji: "🐓",
        title: "La réhausse des acrotères des terrasses inaccessibles",
        date: "Avril 2021 - janvier 2022",
        duration: "18 semaines (6 semaines par tranche)",
        description:
          "Cette opération consiste à rehausser les acrotères des terrasses inaccessibles. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 3 semaines pour chaque tranche: tranche 1 (avril - mai 2021), tranche 2 (août - septembre 2021), tranche 3 (décembre 2021 - janvier 2022)",
      },
      {
        id: 2,
        emoji: "💧",
        title: "L’étanchéité des terrasses inaccessibles",
        date: "Avril 2021 - janvier 2022",
        duration: "12 semaines (4 semaines par tranche)",
        description:
          "Cette opération consiste à faire l’étanchéité des terrasses inaccessibles. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 3 semaines pour chaque tranche: tranche 1 (avril - mai 2021), tranche 2 (septembre 2021), tranche 3 (janvier 2022)",
      },
      {
        id: 3,
        emoji: "💧",
        title: "L’étanchéité des balcons et des terrasses privatives",
        date: "Mai 2021 - février 2022",
        duration: "9 semaines (3 semaines par tranche)",
        description:
          "Cette opération consiste à faire l’étanchéité des balcons des logements et des terrasses privatives. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 3 semaines pour chaque tranche: tranche 1 (mai - juin 2021), tranche 2 (septembre - octobre 2021), tranche 3 (janvier - février 2022)",
      },
      {
        id: 4,
        emoji: "🔨",
        title: "Le remplacement des garde corps des terrasses privatives",
        date: "Mai 2021 - février 2022",
        duration: "9 semaines (3 semaines par tranche)",
        description:
          "Cette opération consiste à remplacer les garde-corps de toutes les terrasses privatives. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 3 semaines pour chaque tranche: tranche 1 (mai - juin 2021), tranche 2 (septembre - octobre 2021), tranche 3 (janvier - février 2022)",
      },
      {
        id: 5,
        emoji: "🌫",
        title: "Le changement des ventilateurs en toiture",
        date: "Juin 2021 - février 2022",
        duration: "6 semaines (2 semaines par tranche)",
        description:
          "Cette opération consiste à changer les systèmes de ventilation en toiture. Elle se fera en trois tranches pendant l’année des travaux. Les travaux prennent 2 semaines pour chaque tranche: tranche 1 (juin 2021), tranche 2 (octobre 2021), tranche 3 (février 2022)",
      },
    ],
  },
  {
    id: 5,
    emoji: "🎉",
    title: "La fin des travaux",
    date: "mars 2022",
    duration: "",
    substeps: [
      {
        id: 0,
        emoji: "🔨",
        title: "La mise en services et réglages",
        date: "Mars 2022",
        duration: "non communiquée",
        description: "",
      },
      {
        id: 1,
        emoji: "🔨",
        title: "Les opérations préalables à la réception des travaux",
        date: "Mars 2022",
        duration: "non communiquée",
        description: "",
      },
    ],
  },
];
