import React, { useState } from "react";
import classes from "./CardList.module.css";
// Components
import Card from "./Card/Card";
import CardCreateReclamation from "./CardCreateReclamation/CardCreateReclamation";
import CardUserReclamation from "./CardUserReclamation/CardUserReclamation";
import CardAdminReclamation from "./CardAdminReclamation/CardAdminReclamation";

const CardList = ({ cards, dispatch, executeScroll, filters, claims }) => {
  const [reclamations, setReclamations] = useState();

  const openHandler = (index) => {
    dispatch({ type: "SET_SHOWCARD", show: true });
    dispatch({ type: "SET_SELECTEDCARD", selectedCard: index });
  };

  const { réclamations, travaux } = filters;
  const réclamationsCheck =
    réclamations.value || (!réclamations.value && !travaux.value);
  const travauxCheck = travaux.value || (!travaux.value && !réclamations.value);

  return (
    <div className={classes.CardList}>
      <div className={classes.List}>
        {claims && claims.admin ? (
          <CardAdminReclamation
            reclamations={reclamations}
            setReclamations={setReclamations}
          />
        ) : (
          <>
            <CardUserReclamation
              reclamations={reclamations}
              setReclamations={setReclamations}
            />
            <CardCreateReclamation setReclamations={setReclamations} />
          </>
        )}

        {cards.map((card, index) => {
          if (
            (card.tags === "travaux" && travauxCheck) ||
            (card.tags === "réclamation" && réclamationsCheck)
          ) {
            return (
              <Card
                key={card.id}
                cardName={card.id}
                handleOpen={() => openHandler(index)}
                titleStrong={card.titleStrong}
                titleWeak={card.titleWeak}
                imgSrc={card.thumbnail}
                submitted={card.submitted}
                type={card.type}
                tags={card.tags}
                executeScroll={executeScroll}
                description={card.description.substring(0, 58) + "..."}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};

export default CardList;
