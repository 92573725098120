import React, { useState } from "react";
import classes from "./Projet.module.css";
// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            Après de nombreuses années de service, votre résidence a besoin de
            faire peau neuve ! C’est pourquoi Immobilière 3F a monté, en
            concertation avec l’amicale des locataires, un programme de travaux
            visant à redonner son cachet à l’immeuble, à améliorer le confort
            des logements et à adapter les espaces communs aux nouveaux usages
            des habitants.
          </p>
          <p>
            Ce site internet a vocation à accompagner les locataires tout au
            long des travaux. Vous pourrez y trouver le programme des travaux,
            les dernières informations du chantier ainsi qu’un espace pour
            contacter l’équipe travaux. Bonne visite !
          </p>
          <div className={classes.ActionButton}>
            <p onClick={clicked}>Accéder aux travaux {">"}</p>
            {/* <div className={classes.ActionButtonSvg}>
              <img
                style={{ width: "100%" }}
                alt="hand"
                src={require(`../../../assets/homePage/main.svg`)}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} />
      </div>
    </div>
  );
};

export default Projet;
