import app, { db } from "../config/firebase";
import moment from "moment";
import axios from "axios";

axios.defaults.baseURL =
  "https://europe-west1-projet-homy.cloudfunctions.net/api";

// Codes
export const codesLoc = [
  "g63r07Y",
  "n595I3k",
  "957o1kL",
  "w934s9D",
  "29gK82b",
  "0dz964M",
  "61z2Qk9",
  "1u1Y860",
  "2450tX9",
  "2w42U3q",
  "PCk5960",
  "na02L65",
  "8g5Hi66",
  "2Wh68M9",
  "2k96gD5",
  "1Q6i6x6",
  "U7n99z5",
  "Km190Q1",
  "Xk7602E",
  "x178Zn9",
  "02Q4ha1",
  "g325Q7b",
  "U0274tO",
  "2n922Zn",
  "S7g95v4",
  "xJ210y2",
  "rC22L10",
  "139R8cB",
  "w278I3c",
  "6n3F108",
  "Qu54343",
  "j478U9w",
  "C6w0K74",
  "20r1G7g",
  "gC2551Z",
  "16I62dX",
  "Qd94x74",
  "a9Ny476",
  "f6q70H7",
  "c8t7J67",
  "97N3Q7z",
  "84A30mh",
  "X153Kx5",
  "52X27cd",
  "G13731f",
  "Dxl1047",
  "1PwP219",
  "F3m5m33",
  "U4510sd",
  "3qX821n",
  "Ip181K6",
  "A84M63f",
  "39Hf7h9",
  "w197US2",
  "AN40p01",
  "q729K5e",
  "9K4gA13",
  "22t9Fe2",
  "460C7za",
  "9v3Aw37",
  "s8893wQ",
  "W82nY51",
  "k0783Lw",
  "1Tu08J4",
  "w1i6P22",
  "pK724n5",
  "c55CH93",
  "21bP49C",
  "24kx99O",
  "5b239kU",
  "0g0I02s",
  "j94Q90F",
  "8800YTp",
  "2iIQ254",
  "3eAj511",
  "A631rm6",
  "Q20p5s5",
  "0IxR222",
  "y0S29V8",
  "aWr0191",
  "V5u179q",
  "Y21S8r9",
  "b2e729G",
  "d121x4B",
  "15HtN73",
  "81h9gK9",
  "m656Tj7",
  "e90KC79",
  "K174j8m",
  "598Zq6v",
  "2dK5U81",
  "f9Q95p5",
  "B3pS462",
  "10n8H97",
  "18Jw27m",
  "Nd065y9",
  "u8N233I",
  "ga8344S",
  "3g62j0B",
  "00k86AY",
  "g3V16m8",
  "8857GIf",
  "64R54Lw",
  "61R31vj",
  "V3706vz",
  "3y6m0Z3",
  "007UBb9",
  "99h57MO",
  "90C8R6p",
  "s15Hl08",
  "l34P06a",
  "3Q6qL76",
  "n1812X6",
  "558D2cn",
  "oT604R7",
  "9Ini343",
  "X44w4R9",
  "cq271Q9",
  "24WU9p7",
  "G332l0O",
  "709z6hC",
  "6lj621Y",
  "p1711EJ",
  "43W84z8",
  "1rV2x52",
  "h13N568",
  "1y8O191",
  "4I9b976",
  "4147MVc",
  "Y79do29",
  "4q5M81H",
  "1234567",
  "7654321",
];

// Checks for validity
export const checkValidity = (value, rules) => {
  let isValid = true;

  if (rules.required) {
    isValid = value.trim() !== "" && isValid;
  }

  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid;
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid;
  }

  if (rules.regex) {
    isValid = rules.regex.test(value) && isValid;
  }

  if (rules.codes) {
    isValid = rules.codes.includes(value) && isValid;
  }

  return isValid;
};

// custom token login
export const customLogin = (refLoc, dispatch) => {
  // console.log("Axios function");
  axios
    .post("/login", { code: refLoc.value })
    .then((res) => {
      // console.log(res);
      app
        .auth()
        .signInWithCustomToken(res.data.token)
        .then((res) => {
          // console.log("Auth success: ", res.data);
          dispatch({ type: "SET_LOADING", loading: false });
        })
        .catch((err) => {
          console.log("auth error: ", err);
          dispatch({ type: "SET_LOADING", loading: false });
        });
    })
    .catch((err) => {
      console.log("axios error: ", err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Anon Login
export const anonLogin = (dispatch) => {
  app
    .auth()
    .signInAnonymously()
    .then((resp) => {
      console.log("Login successful");
      dispatch({ type: "SET_LOADING", loading: false });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: "SET_LOADING", loading: false });
    });
};

// Add new locataire
export const addNewLoc = (refLoc, locataireID) => {
  const newLoc = {
    refLoc,
    locataireID,
    dateCreation: moment().format(),
  };
  if (refLoc !== "") {
    db.collection("locataires")
      .doc(refLoc)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  } else {
    db.collection("locataires")
      .doc(`anon-${locataireID}`)
      .set(newLoc)
      .then((resp) => {
        console.log("[New user added successfully]");
      })
      .catch((err) => {
        console.log("Error while adding new user: ", err);
      });
  }
};
