import React from "react";
import classes from "./ReclamationContent.module.css";
// MUI
import { Button } from "@material-ui/core";
import HelpOutlineRoundedIcon from "@material-ui/icons/HelpOutlineRounded";

const StepThree = ({ setStep, tagOne, tagTwo, setTagTwo }) => {
  let categories = [];
  switch (tagOne) {
    case "logement":
      categories = [
        {
          id: "logement",
          value: "logement",
          label: "Tout mon logement",
          image: "appMain.svg",
        },
        {
          id: "salle de bain",
          value: "salle de bain",
          label: "La salle de bain",
          image: "app_salle_de_bain.svg",
        },
        {
          id: "sanitaires",
          value: "sanitaires",
          label: "Les sanitaires",
          image: "app_wc.svg",
        },
        {
          id: "cuisine",
          value: "cuisine",
          label: "La cuisine",
          image: "app_cuisine.svg",
        },
        {
          id: "salon / salle à manger",
          value: "salon / salle à manger",
          label: "Salon / salle à manger",
          image: "app_salle_a_manger.svg",
        },
        {
          id: "chambres",
          value: "chambres",
          label: "La/les chambres",
          image: "app_chambre.svg",
        },
        {
          id: "terrasse",
          value: "terrasse",
          label: "La terrasse",
          image: "app_terrasse.svg",
        },
        {
          id: "balcon",
          value: "balcon",
          label: "Le balcon",
          image: "app_balcon.svg",
        },
        {
          id: "autre",
          value: "autre",
          label: "Autre",
          image: "appAutre.svg",
        },
      ];
      break;
    case "immeuble":
      categories = [
        {
          id: "immeuble",
          value: "immeuble",
          label: "Tout l’immeuble",
          image: "immMain.svg",
        },
        {
          id: "cage d’escalier",
          value: "cage d’escalier",
          label: "La cage d’escalier",
          image: "imm_cage_escalier.svg",
        },
        {
          id: "ascenseur",
          value: "ascenseur",
          label: "L’ascenseur",
          image: "imm_ascenceur.svg",
        },
        {
          id: "hall",
          value: "hall",
          label: "Le Hall",
          image: "imm_hall.svg",
        },
        {
          id: "caves",
          value: "caves",
          label: "Les caves",
          image: "imm_cave.svg",
        },
        {
          id: "locaux communs",
          value: "locaux communs",
          label: "Les locaux communs",
          image: "imm_locaux_communs.svg",
        },
        {
          id: "autre",
          value: "autre",
          label: "Autre",
          image: "immAutre.svg",
        },
      ];
      break;
    case "résidence":
      categories = [
        {
          id: "résidence",
          value: "résidence",
          label: "Toute la résidence",
          image: "residMain.svg",
        },
        {
          id: "cours",
          value: "cours",
          label: "La / les cours",
          image: "resid_cour_immeuble.svg",
        },
        {
          id: "cheminements extérieurs",
          value: "cheminements extérieurs",
          label: "Cheminements extérieurs",
          image: "resid_chemin_ext.svg",
        },
        {
          id: "jardin",
          value: "jardin",
          label: "Le jardin",
          image: "resid_jardins.svg",
        },
        {
          id: "accès",
          value: "accès",
          label: "Les accès",
          image: "resid_acces.svg",
        },
        {
          id: "parkings",
          value: "parkings",
          label: "Les parkings",
          image: "resid_parking.svg",
        },
        {
          id: "autre",
          value: "autre",
          label: "Autre",
          image: "residAutre.svg",
        },
      ];
      break;
    case "autre":
      setStep(3);
      break;
  }

  return (
    <div className={classes.StepContent}>
      <div className={classes.Question}>
        <div className={classes.QuestionTitle}>
          <HelpOutlineRoundedIcon style={{ fontSize: 40, color: "#3B4578" }} />
          <p>Veuillez préciser l’endroit concerné par votre demande</p>
        </div>
        <form className={classes.RadioFormTwo}>
          {categories.map((option) => (
            <div className={classes.RadioElementTwo} key={option.id}>
              <input
                type="radio"
                name={option.id}
                value={option.value}
                onChange={(e) => {
                  setTagTwo(e.target.value);
                  setStep(3);
                }}
                id={option.id}
              />
              <label
                htmlFor={option.value}
                style={{
                  backgroundImage: `url(${require(`../../../../assets/travaux/reclamation/${option.image}`)})`,
                }}
              ></label>
              <p>{option.label}</p>
            </div>
          ))}
        </form>
      </div>
      <div className={classes.Buttons}>
        <Button
          color="primary"
          size="medium"
          style={{
            width: "6rem",
            color: "#333333",
            backgroundColor: "#E0E0E0",
          }}
          onClick={() => setStep(1)}
        >
          Retour
        </Button>
      </div>
    </div>
  );
};

export default StepThree;
