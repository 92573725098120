import React from "react";
import classes from "./Filter.module.css";

const Filter = ({ filters, dispatch }) => {
  return (
    <div className={classes.Filter}>
      <div className={classes.Categories}>
        <form>
          {Object.keys(filters).map((filter) => {
            return (
              <div
                className={
                  filters[filter].id === "réclamations"
                    ? classes.Personnalisable
                    : classes.Informative
                }
                key={filters[filter].id}
              >
                <input
                  type="checkbox"
                  checked={filters[filter].value}
                  id={filters[filter].id}
                  onChange={() =>
                    dispatch({
                      type: "SET_FILTERS",
                      id: filters[filter].id,
                      value: !filters[filter].value,
                    })
                  }
                />
                <label htmlFor={filters[filter].id}>{filters[filter].id}</label>
              </div>
            );
          })}
        </form>
      </div>
    </div>
  );
};

export default Filter;
