import React, { useState } from "react";
import classes from "./Calendar.module.css";
// MUI
// data
import { CalendarData } from "../../../data/CalendarData";
// Components
import CalendarDesktop from "./CalendarDesktop/CalendarDesktop";
import CalendarMobile from "./CalendarMobile/CalendarMobile";

const Calendar = ({ width }) => {
  const [selectedCard, setSelectedCard] = useState(0);
  const [show, setShow] = useState(false);

  if (width < 768) {
    return (
      <CalendarMobile
        CalendarData={CalendarData}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        show={show}
        setShow={setShow}
      />
    );
  } else {
    return (
      <CalendarDesktop
        CalendarData={CalendarData}
        selectedCard={selectedCard}
        setSelectedCard={setSelectedCard}
        show={show}
        setShow={setShow}
      />
    );
  }
};

export default Calendar;
